<template>
  <div class="flex flex-col my-6 gap-5">
    <div>
      <strong class="text-base">Bundle product</strong>
      <p>
        Create a bundle product to allow multiple product purchase in 1 product.
      </p>
    </div>
    <vs-input
      class="w-1/3"
      label="Bundle heading"
      v-model="product.productTypeHeading"
      name="productTypeHeading"
 
        v-validate="'required'"
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first(`productTypeHeading`) ? true : false"
        :danger-text="errors.first(`productTypeHeading`) ? `Field Bundle heading is required` : ''"
        :success="!errors.first(`productTypeHeading`) && product.productTypeHeading != ''"
    />
    <vs-input
      class="w-1/3"
      label="Bundle description"
      v-model="product.productTypeDescription"
    />
    <vs-button type="border" class="mt-2 w-1/5" @click="addField()"
      >Add Field</vs-button
    >
    <div
      v-for="(item, index) in product.options"
      v-bind:key="`options-${index}`"
      class="flex flex-row items-center gap-2"
    >
      <vs-input
        class="w-1/3"
        :label="`Field ${index + 1}`"
        :name="`option_${index}`"
        v-model="item.value"
        v-validate="'required'"
        val-icon-success="done"
        val-icon-danger="error"
        :danger="errors.first(`basic.option_${index}`) ? true : false"
        :danger-text="errors.first(`basic.option_${index}`) ? `Field ${index + 1} is required` : ''"
        :success="!errors.first(`basic.option_${index}`) && item.value != ''"
        data-vv-scope="basic"
        />
      <vs-button
        v-if="product.options.length !== 2"
        color="danger"
        type="border"
        icon="remove"
        :class="!errors.first(`basic.option_${index}`) ? 'mt-5' : ''"
        @click="removeOption(index)"
        ></vs-button>
    </div>
    <vs-input
      type="number"
      class="w-1/3"
      label="Minimum qty in bundle of all selected products"
      name="minQty"
      v-model="product.minQty"
      val-icon-success="done"
      :danger="errors.first('product.minQty') ? true : false"
      :danger-text="errors.first('product.minQty') ? 'Minimum quantity is required' : ''"
      :success="!errors.first('product.minQty') && product.minQty != ''"
      data-vv-scope="product"
      val-icon-danger="error"
      v-validate="{
        required: true,
        min_value: 1,
      }"
      data-vv-as="minQty"
    />
    <vs-input
      class="w-1/3 customFontColor"
      label="Minimum error message"
      v-model="product.minErrorMessage"
    />
    <vs-input
      type="number"
      class="w-1/3"
      label="Maximum qty in bundle of all selected products"
      name="maxQty"
      v-model="product.maxQty"
    />
    <vs-input
      class="w-1/3 customFontColor"
      label="Maximum error message"
      v-model="product.maxErrorMessage"
    />
  </div>
</template>

<script>
export default {
  name: "BundleProduct",
  inject: ["$validator"],
  props: ["product"],
  methods: {
    addField() {
      this.product.options.push({ value: "" });
    },
    removeOption(index) {
      this.product.options.splice(index, 1);
    },
  },
  watch: {
    product: {
      handler(newVal) {
        if (newVal.options.length === 1) {
          // If the product type is bundle and there is only one option, add another field
          this.addField();
        }
        this.$emit("updateProductType", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
.customFontColor {
  color: red;
}
</style>
