<template>
  <div class="flex flex-col my-6 gap-5">
    <div>
      <strong class="text-base">Lead form product</strong>
      <p>Create a lead form product to send email inquiries to suppliers.</p>
    </div>
    <vs-input
      class="w-1/3"
      label="Lead form heading"
      v-validate="'required'"
      v-model="product.productTypeHeading"
      name="productTypeHeading"
      val-icon-success="done"
      val-icon-danger="error"
      :danger="errors.first('basic.productTypeHeading') ? true : false"
      :danger-text="errors.first('basic.productTypeHeading') ? 'Lead form heading is required' : ''"
      :success="!errors.first('basic.productTypeHeading') && product.productTypeHeading != ''"
      data-vv-scope="basic"
    />
    <vs-input
      class="w-1/3"
      label="Lead form description"
      v-model="product.productTypeDescription"
    />
    <vs-button type="border" class="mt-2 w-1/5" @click="addField()"
      >Add Field</vs-button
    >
    <div
      v-for="(item, index) in product.options"
      v-bind:key="`options-${index}`"
      class="flex flex-row items-center gap-2"
    >
      <vs-input
        class="w-1/3"
        :label="`Option ${index + 1}`"
        v-model="item.value"
        :name="`option_${index}`"
      />
      <vs-button
        v-if="product.options.length !== 1"
        color="danger"
        type="border"
        icon="remove"
        class="mt-5"
        @click="removeOption(index)"
      ></vs-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeadFormProduct",
  inject: ["$validator"],
  props: ["product"],
  methods: {
    addField() {
      this.product.options.push({ value: "" });
    },
    removeOption(index) {
      this.product.options.splice(index, 1);
    },
  },
  watch: {
    product: {
      handler(newVal) {
        this.$emit("updateProductType", newVal);
      },
      deep: true,
    },
  },
};
</script>
