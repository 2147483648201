<template>
  <div class="flex flex-col my-6 gap-5">
    <div>
      <label class="text-base">Select Items</label>
    </div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
        <AjaxSelect @onProductAdded="onProductAdded" />
      </vs-col>
    </vs-row>
    <vs-row
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      vs-w="12"
      v-for="(item, index) in bundledItems"
      :key="index"
    >
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
        <div class="w-full mr-5">
          <vs-input
            :success="!errors.first('basic.name') && item.name != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.name') ? true : false"
            v-validate="'required'"
            disabled
            name="productName"
            data-vv-as="Product Name"
            label="Product"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.name')"
            v-model="item.name"
          />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="end" vs-w="3">
        <div class="w-full mr-5">
          <vs-input
            :success="!errors.first('basic.quantity') && item.quantity != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :danger="errors.first('basic.quantity') ? true : false"
            v-validate="'required|decimal:2|min_value:1'"
            name="quantity"
            data-vv-as="Quantity"
            label="Quantity"
            class="w-full"
            data-vv-scope="basic"
            :danger-text="errors.first('basic.quantity')"
            v-model="item.quantity"
          />
        </div>
        <vs-button
          color="danger"
          type="border"
          icon="remove"
          class="m-auto mt-4"
          @click="removeOption(index)"
        ></vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import AjaxSelect from "../../../webstore-discounts/AjaxSelect.vue";
export default {
  name: "BundledItems",
  inject: ["$validator"],
  props: ["bundledItems", "newBundleItems"],
  components: {
    AjaxSelect,
  },
  methods: {
    onProductAdded(product) {
      this.$emit("newBundleItems", product);
    },
    removeOption(index) {
      this.bundledItems.splice(index, 1);
    },
  },
};
</script>
